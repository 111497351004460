<template>
<div class="dashboard-tabs">
	<div class="dashboard-tabs__header">
		<ul class="list">
			<li
				v-for="tab in tabs"
				:key="tab.id"
				class="tab cursor-pointer"
				:class="[{'tab--selected': selected === tab.id}, {'text-blue-500': labelColorBlue}]"
				@click="$emit('select', tab.id)"
			>{{tab.label}}</li>
		</ul>
		<div class="additional-controls">
			<slot/>
		</div>
	</div>
	<hr>
	<div
		v-if="$slots['content-' + selected]"
		class="dashboard-tabs__content flex flex-1"
	>
		<slot :name="'content-' + selected"/>
	</div>
</div>
</template>

<script>
export default {
	name: 'tabs',
	props: {
		tabs: Array,
		selected: [String, Number],
		labelColorBlue: {
			type: Boolean,
			default: true,
		},
	},
	
}
</script>

<style lang="scss" scoped>
.dashboard-tabs {
	width: 100%;
	&__header {
		display: flex;
		align-items: center;
		gap: 20px;
		justify-content: space-between;
		.list {
			display: flex;
			align-items: center;
			gap: 20px;
			.tab {
				height: 48px;
				min-width: 180px;
				padding: 0 15px;
				background-color: #F1F5F6;
				color: rgba(40, 40, 40, 0.6);
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 12px 12px 0 0;
				font-size: 18px;
				font-weight: bold;
				&--selected {
					background-color: #0D69D5;
					color: #fff;
				}
			}
		}
	}
	&__content {
		margin-top: 20px;
	}
}
</style>